import React from "react"
import { RightArrow } from "../components/SVGIIcon"

import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import ImgCareer from "../components/img/ImgCareer"

const SecondPage = () => (
  <Layout>
    <PageHeader title="Careers" displayTitle="We are Hiring!" subtitle="Grab the opportunity to be a part of our fantastic team." />
    <section className="mt-5">
      <div className="container background-plus">
        <div className="row">
          <div className="col-lg-6 mobile-margin-top">
            <ImgCareer />
          </div>
          <div className="col-lg-6">
            <div className="col-limit-3">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Work with us</h2>
            </div>
            <p>
            At VoerEir, we firmly uphold four core values: <strong>knowledge, integrity, empathy, and efficiency</strong>.</p>
              <p>With our team boasting a collective experience exceeding 50 years, 
                joining us means gaining access to the latest cloud technologies taught by industry experts.</p>
              <p>Our work culture is a point of pride, offering a super cool environment that embraces 
                flexibility and empowers our employees.</p>
          </div>
        </div>       
        <div className="row">
        <div className="col-lg-12">
            <div className="col-limit-3">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Open positions</h2>
            </div>
        
            <div className="careers mt-3">
              {/*<h4>Engineering</h4>*/}
              {job_eng_data.map((o, i) => (
                <Job {...o} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SecondPage

const Job = ({ name, location, link = "#" }) => (
  <a rel="noopener noreferrer" href={link} target="_blank">
      <div className="job_name">
        {name}
        <span>
          <RightArrow />
        </span>
      </div>
      <div className="job_location">{location}</div>
  </a>
)

const job_eng_data = [
  {
    name: "Member of Technical Staff-1",
    location: "Noida, India",
    link:
      "https://drive.google.com/file/d/1cYKMVQQU12GB1za2FUepUs8uV5yCel13/view?usp=sharing",
  },
  {
    name: "Senior Member Of Technical Staff (Cloud)",
    location: "Noida, India",
    link:
      "https://drive.google.com/file/d/1JBHTg5Ef9S0S-A96rYpVtTglWRt3ZwQz/view",
  },
    /*{
        name: "Kubernetes Infrastructure Engineer",
        location: "Noida, India",
        link:
            "https://drive.google.com/file/d/1kIbxnTlOS7w-eGuvyYxoWjR-UvEaLs-l/view",
    },
    {
        name: "OpenStack Infrastructure Engineer",
        location: "Noida, India",
        link:
            "https://drive.google.com/file/d/1DKZ8d-oSMqebNFNcQ6iCe0JXiiSqyNxo/view",
    },
    /*{
      name: "Senior Software Engineer In Test (API Automation)",
      location: "Noida, India",
      link:
          "https://drive.google.com/file/d/1qDHBNTyvedZWKmBu0KI4MPn-TJbobkYZ/view",
  }*/
]
